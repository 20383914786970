import React, { useState } from "react";
import "./head.css";
import imgLogo from "../img/logo-mn.png";
import { Link } from "react-router-dom";

function Header() {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <div className="full-head">
      <div className="top-head">
        <div>
          <div className="contact-info">
            <span>
              <span>
                <img src="" alt="" />
              </span>
              +91 234 567 890
            </span>
            <span> | </span>
            <span>
              <a id="maill" href="mailto:business@knoxo.in">business@knoxo.in</a>
            </span>
          </div>
        </div>
      </div>

      <header className="header">
        <div className="logo">
          <img src={imgLogo} alt="CA Firm Logo" width="100" />
        </div>
        <div className={`nav-block ${navOpen ? 'active' : ''}`}>
          <div className="exit nav-option" onClick={toggleNav}>
            <i className="fa-solid fa-circle-xmark"></i>
          </div>
          <div className="nav-option">
            <Link to="/" onClick={closeNav}>Home</Link>
          </div>
          <div className="nav-option">
            <Link to="/about" onClick={closeNav}>About Us</Link>
          </div>
          <div className="nav-option">
            <Link to="/teaminfo" onClick={closeNav}>Our Team</Link>
          </div>
          <div className="nav-option">
            <Link to="/services" onClick={closeNav}>Services</Link>
          </div>
          <div className="nav-option">
            <Link to="/testimonials" onClick={closeNav}>Testimonials</Link>
          </div>
          <div className="nav-option">
            <Link to="/appointment" onClick={closeNav}>Book Appointment</Link>
          </div>
          <div className="contact1 nav-option show">
            <button id="btn" onClick={closeNav}>Contact Us</button>
          </div>
        </div>
        <div className="header-buttons">
          <div className="tooltip-wrapper">
            <div className="contact"></div>
          </div>
          <div className="contact">
            <button>Contact Us</button>
          </div>
        </div>

        <div className="responsive-buttons" onClick={toggleNav}>
          <div className="menu-icon">
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
