import React from "react";
import './an.css'
function Services() {
  return (
    <div>
      <div className="about-hero">
        <h1 className="about-title">Services</h1>
      </div>

      <div className="we-offer">
        <div className="title">
          <h2>What we offer!</h2>
        </div>
        <div className="services-grid">
          <div className="card">
            <div className="content">
              <div className="icon">💼</div>
              <div className="title">Tax Consulting</div>
              <div className="description">
                Our tax consulting services are geared towards minimizing tax
                liabilities while ensuring compliance with all relevant laws and
                regulations. We provide strategic tax planning, advisory
                services, and representation in tax matters.
              </div>
            </div>
            <div className="overlay">
              <button className="learn-more" fdprocessedid="54tr59">
                <a href="/services">See More</a>
              </button>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <div className="icon">💼</div>
              <div className="title">ITR Filing</div>
              <div className="description">
                We assist individuals and businesses in filing their Income Tax
                Returns (ITR) accurately and on time. Our team ensures that all
                deductions and exemptions are utilized to maximize tax savings.
              </div>
            </div>
            <div className="overlay">
              <button className="learn-more" fdprocessedid="hqz5xk">
                <a href="/services">See More</a>
              </button>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <div className="icon">💼</div>
              <div className="title">Company Registration</div>
              <div className="description">
                Starting a new business? Our experts guide you through the
                process of company formation, including selecting the right
                business structure, obtaining necessary registrations, and
                complying with legal formalities.
              </div>
            </div>
            <div className="overlay">
              <button className="learn-more" fdprocessedid="yuwod6">
                <a href="/services">See More</a>
              </button>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <div className="icon">💼</div>
              <div className="title">Business Startup Consulting</div>
              <div className="description">
                From business idea validation to market entry strategy, our
                startup consulting services help entrepreneurs navigate the
                complexities of starting and scaling their businesses. We
                provide guidance on business planning, financial forecasting,
                and regulatory compliance.
              </div>
            </div>
            <div className="overlay">
              <button className="learn-more" fdprocessedid="dptw2">
                <a href="/services">See More</a>
              </button>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <div className="icon">💼</div>
              <div className="title">GST Return Filing</div>
              <div className="description">
                Stay GST compliant with our reliable GST return filing services.
                We handle the entire process, from computation to submission,
                ensuring accuracy and timely compliance.
              </div>
            </div>
            <div className="overlay">
              <button className="learn-more" fdprocessedid="5edge">
                <a href="/services">See More</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
