import React, { useState } from "react";
import './head.css';

function Footer() {
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        service: "",
        comments: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(form);
        // Handle form submission (e.g., send the data to a server)
    };

    return (
        <div>
            <div className="contact-form-container">
                <div className="contact-form-title">
                    <h2>Get in touch with us!!</h2>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>First Name*</label>
                        <input
                            type="text"
                            name="firstName"
                            onChange={handleChange}
                            required
                            value={form.firstName}
                        />
                    </div>
                    <div className="form-group">
                        <label>Last Name*</label>
                        <input
                            type="text"
                            name="lastName"
                            onChange={handleChange}
                            required
                            value={form.lastName}
                        />
                    </div>
                    <div className="form-group">
                        <label>Business Email*</label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            required
                            value={form.email}
                        />
                    </div>
                    <div className="form-group">
                        <label>Company*</label>
                        <input
                            type="text"
                            name="company"
                            onChange={handleChange}
                            required
                            value={form.company}
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number*</label>
                        <input
                            type="tel"
                            name="phone"
                            onChange={handleChange}
                            required
                            value={form.phone}
                        />
                    </div>
                    <div className="form-group">
                        <label>Service of Interest*</label>
                        <input
                            type="text"
                            name="service"
                            onChange={handleChange}
                            required
                            value={form.service}
                        />
                    </div>
                    <div className="form-group">
                        <label>Comments</label>
                        <textarea
                            name="comments"
                            onChange={handleChange}
                            value={form.comments}
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="submit-button"
                    >
                        Submit
                    </button>
                </form>
            </div>
            <footer className="footer">
                <p>© 2024 CA Firm. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default Footer;
