import React, { useState } from 'react';
import './an.css';
import img1 from '../img/image1-Cb7_zW0i.png';
import img2 from '../img/image2-mSznOOot (1).png';
import img3 from '../img/image2-mSznOOot.png';
import img4 from '../img/image3-ClOpBwAU.png';
import img5 from '../img/contactus-QW1mBBua.png';

function About() {
    const [selectedImg, setSelectedImg] = useState(null);
    const [selectedAlt, setSelectedAlt] = useState('');

    const handleImgClick = (imgSrc, imgAlt) => {
        setSelectedImg(imgSrc);
        setSelectedAlt(imgAlt);
    }

    const handleCloseClick = () => {
        setSelectedImg(null);
    }

    return (
        <div>
            <div className="about-hero">
                <h1 className="about-title">About Us</h1>
            </div>
            <div>
                <div className="about-text-container">
                    <h4>[X] years of experience</h4>
                    <p>ABC Accounting Firm brings over [X] years of experience in delivering tailored financial solutions that meet the unique needs of our clients. Our firm was founded with a vision to provide not just services, but strategic guidance that fosters long-term financial health and growth. We understand the challenges businesses face today and offer proactive solutions to mitigate risks and optimize opportunities.</p>
                </div>
            </div>
            <div className="gallery-box">
                <div style={{ textAlign: 'center' }}>
                    <h2>Tabbed Image Gallery</h2>
                    <p>Click on the images below:</p>
                </div>
                <div className="row-gallery">
                    <div className="column">
                        <img src={img1} alt="Snow" onClick={() => handleImgClick(img1, 'Snow')} style={{ width: '100%' }} />
                    </div>
                    <div className="column">
                        <img src={img2} alt="Nature" onClick={() => handleImgClick(img2, 'Nature')} style={{ width: '100%' }} />
                    </div>
                    <div className="column">
                        <img src={img3} alt="Lights" onClick={() => handleImgClick(img3, 'Lights')} style={{ width: '100%' }} />
                    </div>
                    <div className="column">
                        <img src={img4} alt="Mountains" onClick={() => handleImgClick(img4, 'Mountains')} style={{ width: '100%' }} />
                    </div>
                    <div className="column">
                        <img src={img5} alt="Nature" onClick={() => handleImgClick(img5, 'Nature')} style={{ width: '100%' }} />
                    </div>
                </div>
                {selectedImg && (
                    <div className="container show">
                        <span className="closebtn" onClick={handleCloseClick}>&times;</span>
                        <img id="expandedImg" src={selectedImg} alt={selectedAlt} />
                        <div id="imgtext">{selectedAlt}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default About;
