import "./App.css";
import Header from "./hd/Head.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home.js";
import Tools from "./components/Tools.js";
import Footer from "./hd/Footer.js";
import About from "./components/About.js";
import TeamInfo from "./components/TeamInfo.js";
import Services from "./components/Services.js";
import Feeds from "./components/Feeds.js";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/teaminfo" element={<TeamInfo/>} />
        <Route path="services" element={<Services/>}/>
        <Route path="testimonials" element={<Feeds/>} />
        {/* Remove or replace the empty route */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
