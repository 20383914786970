import React from "react";

function TeamInfo() {
  return (
    <div>
      <div className="team-hero">
        <h1 className="team-title">Meet our Team</h1>
      </div>

      <div className="team-text">
        <div className="team-text-box">
          <div className="title">Our Team</div>
          <div className="text">
            Our team comprises highly skilled professionals who are experts in
            their respective fields. From Chartered Accountants and tax advisors
            to business consultants and compliance specialists, each member of
            our team is dedicated to delivering exceptional service with a
            client-centric approach. We believe in continuous learning and
            staying abreast of industry trends to ensure we provide the most
            relevant and effective advice to our clients.
          </div>
        </div>
      </div>

      <div className="team-members-container">
        <h2>Team Members</h2>
        <div className="team-members-box">
          <div className="member-card">
            <div className="member-pic">
              <div className="box"></div>
            </div>
            <div className="member-text-box">
              <div className="member-title">Lorem ipsum</div>
              <div className="member-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </div>
            </div>
          </div>
          <div className="member-card">
            <div className="member-pic">
              <div className="box"></div>
            </div>
            <div className="member-text-box">
              <div className="member-title">Lorem ipsum</div>
              <div className="member-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </div>
            </div>
          </div>
          <div className="member-card">
            <div className="member-pic">
              <div className="box"></div>
            </div>
            <div className="member-text-box">
              <div className="member-title">Lorem ipsum</div>
              <div className="member-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </div>
            </div>
          </div>
          <div className="member-card">
            <div className="member-pic">
              <div className="box"></div>
            </div>
            <div className="member-text-box">
              <div className="member-title">Lorem ipsum</div>
              <div className="member-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamInfo;
