import React from 'react';
import img1 from '../img/image1-Cb7_zW0i.png';
import img2 from '../img/image2-mSznOOot (1).png'

const Feeds = () => {
    return (
        <div>


            <div>
                <div className="about-hero">
                    <h1 className="about-title">Testimonials</h1>
                </div>
            </div>

            <div className="feed-tem">
                <div className="feed-title">
                    <h1>User Feeds</h1>
                </div>

                <div className="feed-box">
                    <div className="feed-box-content1">
                        <div className="box">
                            <div className="box-img">
                            <img src={ img1} alt="" />
                            </div>
                            <div className="feed-content">
                            <h1><strong>Company Name </strong> - CEO</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officia totam exercitationem quis ea dolorem assumenda quam nostrum sint recusandae unde adipisci sapiente tenetur libero doloribus reprehenderit et consectetur omnis!</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="box-img">
                            <img src={ img2} alt="" />
                            </div>
                            <div className="feed-content">
                            <h1><strong>Company Name </strong> - CEO</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officia totam exercitationem quis ea dolorem assumenda quam nostrum sint recusandae unde adipisci sapiente tenetur libero doloribus reprehenderit et consectetur omnis!</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="box-img">
                            <img src={ img1} alt="" />
                            </div>
                            <div className="feed-content">
                                
                            <h1><strong>Company Name </strong> - CEO</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officia totam exercitationem quis ea dolorem assumenda quam nostrum sint recusandae unde adipisci sapiente tenetur libero doloribus reprehenderit et consectetur omnis!</p>
                            </div>
                        </div>
                    </div>
                    <div className="feed-box-content1">
                        <div className="box">
                            <div className="box-img">
                            <img src={ img2} alt="" />
                            </div>
                            <div className="feed-content">
                            <h1><strong>Company Name </strong> - CEO</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officia totam exercitationem quis ea dolorem assumenda quam nostrum sint recusandae unde adipisci sapiente tenetur libero doloribus reprehenderit et consectetur omnis!</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-img">
                                <img src={ img1} alt="" />
                            </div>
                            <div className="feed-content">
                                <h1><strong>Company Name </strong> - CEO</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officia totam exercitationem quis ea dolorem assumenda quam nostrum sint recusandae unde adipisci sapiente tenetur libero doloribus reprehenderit et consectetur omnis!</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    )
}

export default Feeds
