import React from "react";
import "./hm.css";
import imgc from '../img/c-profile-T32TCT77.jpg'

function Home() {
  return (
    <>
      <div className="hm-cs">
        <div className="hm-t">
          <h1>Welcome to ABC Accounting Firm</h1>
          <button>Know More</button>
        </div>
      </div>
      <section className="company-profile">
        <div className="cp-img">
          <img src={imgc} alt="" />
        </div>
        <div className="cp-text-container">
          <div className="cp-text">
            <h3>Welcome to ABC Accounting Firm</h3>
            <p className="cp-para">
              ABC Accounting Firm is a leading provider of comprehensive
              financial services dedicated to empowering businesses and
              individuals alike. With a steadfast commitment to excellence,
              integrity, and personalized service, we are your trusted partner
              in navigating the complexities of financial management and
              compliance.
            </p>
            <ul>
              <li>
                <span>
                  <i className="fa-solid fa-circle-check"></i>
                </span>
                Empowering Your Financial Future
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-circle-check"></i>
                </span>
                Excellence in Every Transaction
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-circle-check"></i>{" "}
                </span>
                Your Partner in Financial Success
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-circle-check"></i>
                </span>
                Navigating Complexity, Delivering Simplicity
              </li>
            </ul>
          </div>
        </div>
      </section>


      
      <div className="services-bx">
        <div className="title">
          <h2>Why Choose Us?</h2>
        </div>
        <section className="services">
          <div className="wcu-item">
            <div className="name">Expertise</div>
            <div className="desc">
              Our team comprises seasoned professionals with extensive
              experience across various industries.
            </div>
          </div>
          <div className="wcu-item">
            <div className="name">Personalized Service</div>
            <div className="desc">
              We take the time to understand your unique needs and tailor our
              solutions accordingly.
            </div>
          </div>
          <div className="wcu-item">
            <div className="name">Client-Centric Approach</div>
            <div className="desc">
              Your success is our priority. We are committed to building
              long-term relationships based on trust and mutual respect.
            </div>
          </div>
          <div className="wcu-item">
            <div className="name">Innovation</div>
            <div className="desc">
              We leverage technology and best practices to deliver efficient and
              effective solutions.
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
